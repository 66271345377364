<template>
  <div class="survey">
    <div class="row">
      <div class="col-lg-12 col-xl-12 order-lg-1 order-xl-1">
        <div class="widget progress-list">
          <div class="widget-header">
            <div class="widget-header-label">
              <h3>
                Historik <small>{{ ticketData.timeline.length }} händelser</small>
              </h3>
            </div>
          </div>
          <div class="widget-body no-padding">
            <vuetable
                class="clickable-vuetable"
                ref="vuetable"
                @vuetable:row-clicked="onRowClicked"
                :api-mode="false"
                :fields="fields"
                :per-page="perPage"
                :data-manager="dataManager"
                pagination-path="pagination"
                @vuetable:pagination-data="onPaginationData"
            >
            </vuetable>
            <div style="padding-top: 10px;">
              <vuetable-pagination ref="pagination"
                                   @vuetable-pagination:change-page="onChangePage"></vuetable-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vuetable from 'vuetable-2/src/components/Vuetable';
import VuetablePagination from 'vuetable-2/src/components/VuetablePagination';
import _ from 'lodash';
import BojAPI from '@/api/boj';

export default {
  name: 'history',
  components: {
    Vuetable,
    VuetablePagination,
  },
  props: {
    id: Number,
  },
  data() {
    return {
      ticketData: {},
      isLoading: false,
      fields: [
        {
          width: '12%',
          name: 'time',
          title: 'Datum',
          sortField: 'time',
        },
        {
          width: '12%',
          name: 'field',
          title: 'Fält',
          sortField: 'field',
        },
        {
          name: 'old_value',
          title: 'Gammalt värde',
          sortField: 'old_value',
        },
        {
          name: 'new_value',
          title: 'Nytt värde',
          sortField: 'new_value',
        },
        {
          width: '15%',
          name: 'user',
          title: 'Ändrat av',
          sortField: 'user',
        },
      ],
      perPage: 20,
    };
  },

  watch: {
    ticketData(newVal, oldVal) {
      this.$refs.vuetable.refresh();
    },
  },
  beforeRouteEnter(to, from, next) {
    BojAPI.getMember(to.params.id)
        .then((response) => {
          next((vm) => vm.setData(response.data));
        })
        .catch((error) => {
        });
  },
  beforeRouteUpdate(to, from, next) {
    this.supplier = null;
    BojAPI.getMember(to.params.id).then((response) => {
      this.setData(response.data);
      next();
    });
  },

  methods: {
    setData(payload) {
      this.ticketData = payload.data;
      this.isLoading = false;
    },

    onRowClicked(data, field, event) {
      this.$router.push({name: 'ticket', params: {id: data.id}});
    },

    onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData);
    },
    onChangePage(page) {
      this.$refs.vuetable.changePage(page);
    },
    dataManager(sortOrder, pagination) {
      let local = this.ticketData.timeline;

      // sortOrder can be empty, so we have to check for that as well
      if (sortOrder.length > 0) {
        local = _.orderBy(local, sortOrder[0].sortField, sortOrder[0].direction);
      }

      pagination = this.$refs.vuetable.makePagination(local.length, this.perPage);

      let from = pagination.from - 1;
      let to = from + this.perPage;

      return {
        pagination: pagination,
        data: _.slice(local, from, to),
      };
    },
    onActionClicked(action, data) {
      console.log('slot actions: on-click', data.name);
    },
    getTicket: function () {
      BojAPI.getMember(this.id).then((response) => {
        this.ticketData = response.data.data;
        this.isLoading = false;
      });
    },
  },
};
</script>
